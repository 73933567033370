
import store from "./store/index.js"
import axios from "axios";

// Özel bir axios örneği oluştur
const apiInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 10000
});


apiInstance.interceptors.request.use(
    (config) => {
        const authToken = store.state.authToken;
        if (authToken) {
            config.headers['Authorization'] = `Bearer ${authToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

apiInstance.interceptors.response.use(function (response) {
    return response
   }, function(error) {
    console.log("error")
    console.log(error)
    if (error.response==undefined) {
        store.dispatch('logout')
    }else{
        if(error.response.status=='401'){
            store.dispatch('logout')
        }
        return Promise.reject(error.response.data)
    }
   })

export default apiInstance