import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    meta: { title:"Giriş Sayfası" }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/logoutView.vue'),
  },
  {
    path: '/complete-register',
    name: 'CompleteRegister',
    component: () => import('../views/CompleteRegister.vue'),
    meta: { title:"Kayıt tamamla" }
  },
  {
    path: '/forgot-password',
    name: 'FargotPassword',
    component: () => import('../views/FargotPassword.vue'),
    meta: { title:"Şifremi Unuttum" }
  },
  {
    path: '/fixtures',
    name: 'fixtures',
    component: () => import('../views/FixturesView.vue'),
    meta: { requiresAuth: true, requiredAuthority: [0], title:"Demirbaşlar" }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/UsersView.vue'),
    meta: { requiresAuth: true, requiredAuthority: [0], title:"Çalışanlar" }
  },
  {
    path: '/users/:id',
    name: 'usercard',
    component: () => import('../views/UserCard.vue'),
    meta: { requiresAuth: true, requiredAuthority: [0], title:"Çalışan Detay" }
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import('../views/PaymentsView.vue'),
    meta: { requiresAuth: true, requiredAuthority: [0], title:"Avans/Kesinti" }
  },
  {
    path: '/departmans',
    name: 'departmans',
    component: () => import('../views/DepartmansView.vue'),
    meta: { requiresAuth: true, requiredAuthority: [0], title:"Departmanlar" }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/SettingsView.vue'),
    meta: { requiresAuth: true, requiredAuthority: [0], title:"Ayarlar" }
  },
  {
    path: '/settings/:id?',
    name: 'settings',
    component: () => import('../views/SettingsView.vue'),
    meta: { requiresAuth: true, requiredAuthority: [0], title:"Ayarlar" }
  },
  {
    path: '/multinet',
    name: 'multinet',
    component: () => import('../views/MultinetView.vue'),
    meta: { requiresAuth: true, requiredAuthority: [0], title:"Multinet" }
  },
  {
    path: '/leaves',
    name: 'leaves',
    component: () => import('../views/LeavesView.vue'),
    meta: { requiresAuth: true, requiredAuthority: [0], title:"Çalışan İzinleri" }
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('../views/CalendarView.vue'),
    meta: { requiresAuth: true, requiredAuthority: [0,1], title:"Takvim" }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/ProfileView.vue'),
    meta: { requiresAuth: true, requiredAuthority: [1], title:"Profile" }
  },
  {
    path: '/profile-leaves',
    name: 'profile-leaves',
    component: () => import('../views/ProfileLeavesView.vue'),
    meta: { requiresAuth: true, requiredAuthority: [1], title:"İzinlerim" }
  },
  {
    path: '/profile-multinet',
    name: 'profile-multinet',
    component: () => import('../views/ProfileMultinetView.vue'),
    meta: { requiresAuth: true, requiredAuthority: [1], title:"Multinet Detayları" }
  },
  {
    path: '/profile-fixtures',
    name: 'profile-fixtures',
    component: () => import('../views/ProfileFixturesView.vue'),
    meta: { requiresAuth: true, requiredAuthority: [1], title:"Kayıtlı Demirbaşlarım" }
  },
  {
    path: '/profile-payments',
    name: 'profile-payments',
    component: () => import('../views/ProfilePaymentsView.vue'),
    meta: { requiresAuth: true, requiredAuthority: [1], title:"Avanslarım/Kesintilerim" }
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title; // Sayfa başlığını güncelleyin
  } else {
    document.title = 'Fallowizer'; // Varsayılan başlık
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLogin) {
      next({ name: 'login' });
    } else if (to.matched.some(record => record.meta.requiredAuthority && !record.meta.requiredAuthority.includes(store.getters.authority))) {
      next({ name: 'home' });
    } else {
      next();
    }
  } else {
    if (store.getters.isLogin && to.name !== 'logout') {
      next({ name: 'home' });
    } else {
      next();
    }
  }
});

export default router

